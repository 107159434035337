.footer p {
  font-size: 1rem;
  line-height: 1.6;
}

.footer p span {
  display: inline-block;
  font-weight: 500;
  font-size: 1.2rem;
  margin-top: 1rem;
  color: #091741;
  /* margin-bottom: 1rem; */
}

.footer a {
  text-decoration: none;
  color: #1e4dd8;
  line-height: 1.6;
  font-size: 1.2rem;
  font-weight: 500;
}

.coinlist-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;

  row-gap: 4rem;
}

.coinlist-item {
  display: flex;
  /* grid-template-columns: 0.5fr 1fr; */

  gap: 1rem;
  border-radius: 9px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  text-decoration: none;
  color: inherit;
  transition: transform 0.4s;
  height: 8rem;
}

.coinlist-content {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}
.coinlist-content img {
  height: 65%;
}
.coinlist-content div {
  display: flex;
  flex-direction: column;
}
.coinlist-content div h2 {
  color: #091741;
}

.inputLabel {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.settings {
  display: flex;
  gap: 3rem;
  margin-bottom: 2rem;
}

.settings p {
  font-weight: 600;
}

.platform {
  display: grid;
  grid-template-columns: 30rem 1fr;
  height: 100vh;
}

.loading-container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
}

.loading-item p {
  color: #091741;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 1px;
}

.loading-spinner {
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;
}

.loading-spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #1e4dd8;
  border-radius: 50%;
  animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1e4dd8 transparent transparent transparent;
}
.loading-spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading-spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading-spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/**********************************/
/* Header */
/**********************************/

.header {
  display: flex;
  align-items: flex-end;
}

.header p {
  margin-bottom: 11.4rem;
}

/**********************************/
/* Content */
/**********************************/
.content-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: #fff;
  padding: 6.4rem 3.2rem 3.2rem 3.2rem;

  overflow-y: auto;

  max-width: 130rem;
  margin: 0 auto;
  gap: 3.2rem;
}

.main-text {
  margin: 3.2rem 0;
}

.section-wallet-warning {
  margin-bottom: 9.6rem;
}

.section-wallet-warning--text {
  font-weight: 500;
  text-align: center;
}

.main {
  display: flex;
  flex-direction: column;

  height: inherit;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.features-section {
  margin-bottom: 6.4rem;
}

.features--lists {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.features--list {
  list-style-type: none;
  margin: 2.4rem;

  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.features-list--item {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.features-list--item i {
  font-size: 2.4rem;
  color: #1e4dd8;
}

/**
    - Font sizes (px)
    10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98
    
    - Spacing System (px)
    2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
    **/

/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800&display=swap"); */
@import "./components/UI/General.css";

/* Reset */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

.root {
  font-family: "Inter", sans-serif;
  font-size: 1.6rem;
  line-height: 1.6;
  font-weight: 400;

  /* background-color: #fff; */
  color: #333;

  /* display: grid;
  grid-template-columns: 30rem 1fr; */

  height: 100vh;
}
